import { useState, useEffect, useRef } from 'react';

export const useUpdateGame = ({
  socketRef, 
  socketStatus, 

  setGameInfo,
  gameInfoRef, 

  connectionStatusRef,

}) => {


  useEffect(() => {
    const socket = socketRef.current;

    if (socket && socketStatus === 'connected') {
      // Обработчик сообщений чата
      
      const handleChatMessage = (message) => {
        const game = gameInfoRef.current;
        if (game) {
          const newChat = [...game.chat, message];
          setGameInfo((prevGameInfo) => {
            const newGameInfo = {
              ...prevGameInfo,
              chat: newChat
            }
            gameInfoRef.current = newGameInfo;
            return newGameInfo;
          });
        }
      };

      // Обработчик нового слова
      const handleNewWord = ({word, hint, definition}) => {
        setGameInfo((prevGameInfo) => {
          const newGameInfo = {
            ...prevGameInfo,
            word: word,
            hint: hint,
          }
          gameInfoRef.current = newGameInfo;
          return newGameInfo;
        });
      };

      // Обработчик нового определения
      const handleDefinitions = ({definitions}) => {
        setGameInfo((prevGameInfo) => {
          const newGameInfo = {
            ...prevGameInfo,
            definitions: definitions,
          }
          gameInfoRef.current = newGameInfo;
          return newGameInfo;
        });
      };

      // Обработчик нового слова
      const handleNewHint = ({hint}) => {
        setGameInfo((prevGameInfo) => {
          const newGameInfo = {
            ...prevGameInfo,
            hint: hint,
          }
          gameInfoRef.current = newGameInfo;
          return newGameInfo;
        });
      };

      // Обработчик Bru (поделиться кистью)
      const handleShared = ({shared}) => {
        setGameInfo((prevGameInfo) => {
          const newGameInfo = {
            ...prevGameInfo,
            shared: shared,
          }
          gameInfoRef.current = newGameInfo;
          return newGameInfo;
        });
      };

      // Обработчик завершения игры
      const handleFinish = (results) => {

        setGameInfo((prevGameInfo) => {
          const newGameInfo = {
            ...prevGameInfo,
            results,
          }
          gameInfoRef.current = newGameInfo;
          return newGameInfo;
        });

        window.croco?.haptic?.('error');

      };

      // Обработчик обновления превью
      const handlePreview = (lastRenderedStrokeTime) => {
        
        setGameInfo((prevGameInfo) => {
          const newGameInfo = {
            ...prevGameInfo,
            lastRenderedStrokeTime,
          }
          gameInfoRef.current = newGameInfo;
          return newGameInfo;
        });

        connectionStatusRef.current.lastPreviewTime = Date.now();
      };

      // Установка слушателей
      socket.on('chat', handleChatMessage);
      socket.on('word', handleNewWord);
      socket.on('definitions', handleDefinitions);
      socket.on('hint', handleNewHint);
      socket.on('shared', handleShared);
      socket.on('preview', handlePreview);
      socket.on('finish', handleFinish);

      // Очистка слушателей при размонтировании или изменении статуса сокета
      return () => {
        socket.off('chat', handleChatMessage);
        socket.off('word', handleNewWord);
        socket.off('definitions', handleDefinitions);
        socket.off('hint', handleNewHint);
        socket.off('shared', handleShared);
        socket.off('preview', handlePreview);
        socket.off('finish', handleFinish);
      };
    }
  }, [socketStatus, socketRef, setGameInfo, gameInfoRef]);

};
