import chroma from 'chroma-js';
import { useEffect, useCallback, } from 'react';

import { useGameContext  } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useKeyboardHandlers = ({
  changeZoomPc,
  resetZoom,
  zoomFactor,

  undoLastAction,
  redoLastAction,

  changeTool,

}) => {

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();

  const {
    showPremiumMenu,

    toggleMenu, closeMenu,

    setShowPremiumMenu, 
    setShowPremiumInfo, 
    setShowModeHelp,
    showPopupMessage,
  } = gameContext;

  const {
  
    mousePositionRef,
    clickPositionRef,
    zoomMode, setZoomMode,

  } = canvasContext;

  const {
  
    activeTool, previousToolRef, setActiveTool,
    lineWidth, setTheSizeFun,
    lineWidthRef,

    color, setTheColorFun,
    colorRef,
    quickColorChangeTimeRef,

    previousColorRef,
    previousLineWidthRef,

    showPalette, setShowPalette,
  
    shiftIsDownRef,
    altIsDownRef,
    controlIsDownRef,
    spaceIsDownRef,
    tabIsDownRef,
    // keyZIsDownRef,
    // keyXIsDownRef,

    setIsPipettePicking,
    setGradientPalette,
  
  } = brushContext;


  const lineWidthControl = (delta) => {
    const stepSize = Math.round(Math.cbrt(lineWidthRef.current));

    let newLineWidth = lineWidthRef.current + (delta > 0 ? -stepSize : stepSize);
    newLineWidth = Math.max(1, newLineWidth);
    
    setTheSizeFun(newLineWidth);
  }

  const handleKeyDown = useCallback((event) => {

    if (event.target.closest('.input-field')) { return; }

    // Смена инструментов
    if (event.code === 'KeyB') {
      changeTool('brush', { fixed: true });
    } else if (event.code === 'KeyE') {
      changeTool('eraser', { fixed: true });
    } else if (event.code === 'KeyF') {
      changeTool('effect', { fixed: true });
    } 
    
    
    // Запуск меню
    if (event.code === 'KeyW') {
      toggleMenu('word');
    } else if (event.code === 'Backquote') {
      toggleMenu('settings');
    } else if (event.code === 'KeyL') {
      toggleMenu('lag');
    }  


    // if (event.code === 'KeyZ') {
    //   keyZIsDownRef.current = true;
    //   setGradientPalette(true);
    // }

    if (event.key === 'Alt') {
      event.preventDefault();
      if (activeTool !== 'pipette') {
        previousToolRef.current = activeTool;
        altIsDownRef.current = true;
        setActiveTool('pipette');
      }
    }

    if (event.key === 'Control') {
      event.preventDefault();
      controlIsDownRef.current = true;
      setZoomMode(true);
    }

    if (event.code === 'Space') {
      event.preventDefault();
      spaceIsDownRef.current = true;
      setZoomMode(true);
    }

    if (event.code === 'Tab') {
      event.preventDefault();
      tabIsDownRef.current = true;
    }

    if (event.key === 'Shift') {
      event.preventDefault();
      shiftIsDownRef.current = true;
      setShowPalette(true);
    }

    if ((event.ctrlKey && event.code === 'KeyZ') || event.code === 'Backspace') {
      event.preventDefault();
      undoLastAction();
    }

    if ((event.ctrlKey && event.code === 'KeyX')) {
      event.preventDefault();
      redoLastAction();
    }

    if (['KeyX', 'KeyS'].includes(event.code)
      && !event.ctrlKey 
      && !event.altKey
      && !event.shiftKey
    ) {
      event.preventDefault();
      lineWidthControl(-1);
    }
    if (['KeyZ', 'KeyA'].includes(event.code) 
      && !event.ctrlKey 
      && !event.altKey
      && !event.shiftKey
    ) {
      event.preventDefault();
      lineWidthControl(1);
    }

    if ((event.ctrlKey && event.code === 'Digit0')) {
      event.preventDefault();
      resetZoom();
    }

    if (event.code === 'Enter' || event.code === 'Escape') {
      event.preventDefault();
      closeMenu(); 
      setShowPalette(false);
      setShowPremiumMenu(false); 
      setShowPremiumInfo(false); 
      setShowModeHelp(false);
      showPopupMessage(false);
    }
  }, [activeTool, previousToolRef, setActiveTool, setShowPalette, undoLastAction]);

  const handleKeyUp = useCallback((event) => {

    if (event.target.closest('.input-field')) { return; }
    
    if (event.key === 'Alt') {
      altIsDownRef.current = false;
      if (activeTool === 'pipette') {
        setIsPipettePicking(false);
        setActiveTool(previousToolRef.current);
      }
    }

    if (event.key === 'Control') {
      event.preventDefault();
      controlIsDownRef.current = false;
      setZoomMode(false);
    }

    if (event.code === 'Space') {
      event.preventDefault();
      spaceIsDownRef.current = false;
      setZoomMode(false);
    }

    if (event.code === 'Tab') {
      event.preventDefault();
      tabIsDownRef.current = false;
    }

    if (event.key === 'Shift') {
      shiftIsDownRef.current = false;
      setShowPalette(false);
    }

    // if (event.code === 'KeyZ') {
    //   keyZIsDownRef.current = true;
    //   setGradientPalette(false);
    // }
  }, [activeTool, previousToolRef, setActiveTool, setShowPalette]);


  const handleWheel = useCallback((event) => {

    if (event.target.closest('.add-scroll')) { return; }
    if (event.target.closest('.scroll-active')) { return; }
    if (event.target.closest('.premium-menu')) { return; }

    event.preventDefault();

    const delta = event.deltaY > 0 ? 150 : -150;

    if (altIsDownRef.current) {

      const currentColor = chroma(colorRef.current);

      const initialSaturation = currentColor.get('hsv.s');
      const initialHue = currentColor.get('hsv.h');
      const currentBrightness = currentColor.get('hsv.v');
      const currentAlpha = currentColor.alpha();  // Получаем текущий уровень прозрачности
      
      let brightnessDelta = -delta * 0.0003;
      
      let newBrightness = Math.max(0.01, Math.min(1, currentBrightness + brightnessDelta));
      
      let newColor = currentColor;
      newColor = newColor.set('hsv.v', newBrightness);
      
      // Попытка сохранить насыщенность и оттенок при изменении яркости
      newColor = newColor.set('hsv.s', initialSaturation);
      newColor = newColor.set('hsv.h', initialHue);
      
      // Установка сохраненной прозрачности обратно в новый цвет
      newColor = newColor.alpha(currentAlpha);
      
      let finalBrightness = chroma(newColor).get('hsv.v');
      if (!finalBrightness) {return;}
      
      setTheColorFun(newColor.hex());
      quickColorChangeTimeRef.current = Date.now();

      // setActiveTool('brush');

    } else if (shiftIsDownRef.current) {

      let newColor = chroma(colorRef.current).alpha(Math.max(0, Math.min(1, chroma(colorRef.current).alpha() + delta * 0.0005))).hex('rgba');
      setTheColorFun(newColor);
      quickColorChangeTimeRef.current = Date.now();

    } else if (controlIsDownRef.current) {

      changeZoomPc(delta);

    } else {

      lineWidthControl(delta);

    }
  }, []);
  // }, [lineWidth, setTheSizeFun, color, setTheColorFun]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [handleKeyDown, handleKeyUp, handleWheel]);

  return {
  };
};



