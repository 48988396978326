// useMountHistory.js
import { useEffect, useRef } from 'react';

export const useBoardPing = ({
  connectionStatusRef, 
  gameIsLoaded,
  gameInfoRef, 
  playerCountRef,
  hostAction,
}) => {

  const pingIntervalSetRef = useRef();

    useEffect(() => {

    // const game = gameInfoRef.current;
    if (!gameIsLoaded) { return; }
    if (pingIntervalSetRef.current) { return; }

    pingIntervalSetRef.current = true;

    function pingOrReload() {

      if ((playerCountRef.current || 0) < 2) { 
        connectionStatusRef.current.lastServerPing = Date.now();
        return; 
      }
      // if (!game?.board) { return; }
      const now = Date.now();

      if (connectionStatusRef.current.lastServerPing < now - 45 * 1000) { 
        if (connectionStatusRef.current.reloading > now - 15 * 1000) { return; }

        connectionStatusRef.current.reloading = Date.now();
        return window.location.reload();
      }

      hostAction('ping', null, (result)=>{
        connectionStatusRef.current.lastServerPing = Date.now();
      });
    }

    const intervalId = setInterval(() => {
      pingOrReload();
    }, 5* 1000);
    pingOrReload();
    return () => clearInterval(intervalId);

  }, [gameIsLoaded]);

};


export default useBoardPing;
