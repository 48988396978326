// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawing-menu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 270px;
  text-align: center;
  background-color: var(--tg-theme-bg-color);
  padding: 15px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drawing-menu * {
  touch-action: pan-y;
}

.drawing-menu-settings {
  width: 95%;
  margin-top: 4px;
  margin-bottom: 4px;
}

.orientation-options {
  margin-top: 3px;
  display: flex;
  justify-content: center;
}


.drawing-menu-item {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: rgba(154, 154, 154, 0.1);
  border-radius: 10px;
}

.drawing-menu-item-checkbox {
  height: 20px;
  width: 20px;
  margin-left: 2px;
  margin-right: 5px;
}

.drawing-menu-item:hover {
  background-color: rgba(154, 154, 154, 0.15);
}
`, "",{"version":3,"sources":["webpack://./src/components/board/menus/DrawingSetsOverlay.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;EACZ,kBAAkB;EAClB,0CAA0C;EAC1C,aAAa;EACb,yCAAyC;EACzC,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB;;;AAGA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,0CAA0C;EAC1C,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".drawing-menu {\r\n  position: fixed;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  width: 270px;\r\n  text-align: center;\r\n  background-color: var(--tg-theme-bg-color);\r\n  padding: 15px;\r\n  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);\r\n  border-radius: 12px;\r\n  z-index: 1100;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\n.drawing-menu * {\r\n  touch-action: pan-y;\r\n}\r\n\r\n.drawing-menu-settings {\r\n  width: 95%;\r\n  margin-top: 4px;\r\n  margin-bottom: 4px;\r\n}\r\n\r\n.orientation-options {\r\n  margin-top: 3px;\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n\r\n.drawing-menu-item {\r\n  width: 100%;\r\n  margin-top: 6px;\r\n  margin-bottom: 6px;\r\n  background-color: rgba(154, 154, 154, 0.1);\r\n  border-radius: 10px;\r\n}\r\n\r\n.drawing-menu-item-checkbox {\r\n  height: 20px;\r\n  width: 20px;\r\n  margin-left: 2px;\r\n  margin-right: 5px;\r\n}\r\n\r\n.drawing-menu-item:hover {\r\n  background-color: rgba(154, 154, 154, 0.15);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
