// useCanvasSetup.js
import { useEffect, useRef, useState, useCallback } from 'react';
import { useGameContext } from '../../contexts/GameContext';

export const useCanvasSetup = () => {

  const gameContext = useGameContext();
  const { 
    gameInfoRef, 
    gameIsLoaded, 
    canvasBgRef,
   } = gameContext;

  const [canvasInitialized, setCanvasInitialized] = useState(null);

  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const tempCanvasRef = useRef(null);
  const tempContextRef = useRef(null);
  const bgCanvasRef = useRef(null);
  const bgContextRef = useRef(null);
  const softCanvasRef = useRef(document.createElement('canvas'));
  const softContextRef = softCanvasRef.current.getContext('2d');

  const basicSizeRef = useRef(Math.min(window.innerWidth, window.innerHeight * 0.7));
  const visibleSizeRef = useRef(basicSizeRef.current);
  const canvasStyleSizeRef = useRef(basicSizeRef.current * 0.98);
  const canvasSideRef = useRef(800);

  const canvasStyleDimensionsRef = useRef({
    width: basicSizeRef.current * 0.98, 
    height: basicSizeRef.current * 0.98,
  });
  const canvasDimensionsRef = useRef({
    width: 800, 
    height: 800,
  });

  const canvasScaleRef = useRef(canvasStyleDimensionsRef.current.width / canvasDimensionsRef.current.width);
  
  const canvasRectRef = useRef(null);

  const initializeCanvasSize = (thisCanvasRef, thisContexRef, parm = {}) => {

    const game = gameInfoRef.current;

    if (!thisCanvasRef.current) { return; }
    if (!game) { return; }

    game.settings.orientation = game.presetSize ? getOrientation(game.presetSize) : game.settings.orientation;

    if (
      gameInfoRef.current 
      && gameInfoRef.current.mode !== 'sprint'
      ) {

      if (game.presetSize) {
        canvasDimensionsRef.current = game.presetSize;
      } else {

        if (game.settings.orientation === 'vertical') {
          if (game.settings.highRes) {
            canvasDimensionsRef.current = gameInfoRef.current.dimensions?.height === 1920 ? {
              width: 1280,
              height: 1920,
            } : {
              width: 800,
              height: 1200
            };
          } else {
            canvasDimensionsRef.current = {
              width: 800,
              height: 1200
            };
          }
        } else if (game.settings.orientation === 'horizontal') {
          if (game.settings.highRes) {
            canvasDimensionsRef.current = gameInfoRef.current.dimensions?.width === 1920 ? {
              width: 1920,
              height: 1280,
            } : {
              width: 1200,
              height: 800
            };
          } else {
            canvasDimensionsRef.current = {
              width: 1200,
              height: 800
            };
            }
        } else {
          if (game.settings.highRes) {
            canvasDimensionsRef.current = {
              width: 1280,
              height: 1280
            };
          } else {
            canvasDimensionsRef.current = {
              width: 800,
              height: 800
            };
          }
        }

      }

    }

    const canvas = thisCanvasRef.current;

    const context = canvas.getContext('2d');
    context.lineCap = 'round';
    context.lineJoin = 'round';

    canvas.width = canvasDimensionsRef.current.width;
    canvas.height = canvasDimensionsRef.current.height;

    if (parm.fill) {
      context.fillStyle = canvasBgRef.current;
      context.fillRect(0, 0, canvas.width, canvas.height);
    }

    thisContexRef.current = context;

  };

  const changeCanvasStyleDimansions =(thisCanvasRef, thisContexRef) => {

    if (!thisCanvasRef.current) { return; }
    const canvas = thisCanvasRef.current;

    canvas.style.width = `${canvasStyleDimensionsRef.current.width}px`;
    canvas.style.height = `${canvasStyleDimensionsRef.current.height}px`;

  }

  const pageSizeChanged = () => {

    const game = gameInfoRef.current;
    if (!game) { return; }

    const aspectRation = canvasDimensionsRef.current.height / canvasDimensionsRef.current.width;

    if (game.settings.orientation === 'vertical') {

      basicSizeRef.current = Math.min(window.innerWidth * aspectRation, window.innerHeight * 0.71);

      canvasStyleDimensionsRef.current = {
        width: basicSizeRef.current * 0.98 / aspectRation,
        height: basicSizeRef.current * 0.98
      };

    } else if (game.settings.orientation === 'horizontal') {

      basicSizeRef.current = Math.min(window.innerWidth, window.innerHeight);

      canvasStyleDimensionsRef.current = {
        width: basicSizeRef.current * 0.98,
        height: basicSizeRef.current * 0.98 * aspectRation
      };

    } else {

      basicSizeRef.current = Math.min(window.innerWidth, window.innerHeight * 0.7);

      canvasStyleDimensionsRef.current ={
        width: basicSizeRef.current * 0.98, 
        height: basicSizeRef.current * 0.98,
      };
      
    }

    canvasStyleSizeRef.current = basicSizeRef.current * 0.98;

    canvasScaleRef.current = Math.min(canvasStyleDimensionsRef.current.width / canvasDimensionsRef.current.width, canvasStyleDimensionsRef.current.height / canvasDimensionsRef.current.height);

    if (!canvasRef.current) { return; }
    canvasRectRef.current = canvasRef.current.getBoundingClientRect();

    changeCanvasStyleDimansions(canvasRef, contextRef);
    changeCanvasStyleDimansions(bgCanvasRef, bgContextRef);
    changeCanvasStyleDimansions(tempCanvasRef, tempContextRef);
    changeCanvasStyleDimansions(softCanvasRef, softContextRef);

  }


  const getOrientation = (presetSize) =>{
    if (!presetSize) { return 'square'; }
    return presetSize.height > presetSize.width ? 'vertical' :
    presetSize.width > presetSize.height ? 'horizontal' :
    'square';
  }
  
  const prepareCanvases = (parm = {}) => {

    if (!canvasRef.current) { return; }

    initializeCanvasSize(canvasRef, contextRef, { ...parm, fill: true });
    canvasRectRef.current = canvasRef.current.getBoundingClientRect();
    initializeCanvasSize(bgCanvasRef, bgContextRef, parm);
    initializeCanvasSize(tempCanvasRef, tempContextRef, parm);
    initializeCanvasSize(softCanvasRef, softContextRef, parm);
    setCanvasInitialized(true);

    pageSizeChanged();


  }

  useEffect(() => {
    if (gameIsLoaded) {
      prepareCanvases({ initiate: true });
    }
  }, [gameIsLoaded]);

  // setInterval(() => {
  //   pageSizeChanged();
  // }, 5000);

  window.Telegram.WebApp.onEvent('viewportChanged', (()=>{
    pageSizeChanged();
  }))

  return {
    canvasInitialized,
    visibleSizeRef,
    canvasStyleSizeRef,
    canvasSideRef,

    canvasStyleDimensionsRef,
    canvasDimensionsRef,

    canvasScaleRef,
    canvasRectRef,

    canvasRef,
    contextRef,
    tempCanvasRef,
    tempContextRef,
    bgCanvasRef,
    bgContextRef,
    softCanvasRef,
    softContextRef,
  };
};

